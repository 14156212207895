<template>
  <Loader :value="promises.fetch">
    <div class="flatfinder-embed-page">
      <Flatfinder
        v-if="$path('flatfinders.length', item)"
        :value="item.flatfinders"
        :properties="$path('metadata.siteSettings.featuredResidentialProperties', item)"
        :prevent-navigation="true"
      />
    </div>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Flatfinder from '@kvass/flatfinder'
import { eventBus } from '@kvass/flatfinder/src/Component'

export default {
  computed: {
    ...mapState('Project', ['promises', 'item']),
  },
  methods: {
    ...mapActions('Project', ['fetch']),
  },
  created() {
    this.fetch()
    eventBus.on('click', item => {
      if (!window.parent) return
      window.parent.postMessage(
        {
          event: 'kvass:flatfinder:navigation',
          data: item,
        },
        '*',
      )
    })
  },
  metaInfo() {
    return {
      title: this.$tc('flatfinder', 1),
      script: [{ src: '/iframe.resizer.contentwindow.min.js' }],
    }
  },
  components: {
    Flatfinder,
  },
}
</script>

<style lang="scss">
.flatfinder-embed-page {
  .flatfinder {
    &__header {
      display: none;
    }

    &__container {
      padding: 0;
      min-height: initial;
    }

    .flatfinder-leaflet-v1__aspect {
      max-width: none !important;
    }
  }
}
</style>
